<template>
  <div v-if="noticia">
    <div class="card">
      <h3 class="card-title">Editar procedimiento</h3>
      <div class="cols2">
        <pd-input
          type="text"
          id="titulo"
          v-model="noticia.titulo"
          placeholder="Titulo"
          label="Título"
        ></pd-input>
      </div>
      <div class="cols2">
        <pd-input
          v-model="noticia.desde"
          id="desde"
          label="Desde"
          placeholder="Desde"
          type="date"
        ></pd-input>
        <pd-input
          v-model="noticia.hasta"
          id="desde"
          label="Hasta"
          placeholder="Hasta"
          type="date"
        ></pd-input>
      </div>
      <pd-input
        type="file"
        id="archivo"
        placeholder="Archivo"
        label="PDF"
      ></pd-input>
      <div class="form-group my-1">
        <label class="form-label">Contenido</label>
        <quill-editor
          v-model="noticia.contenido"
          :options="editorOption"
        ></quill-editor>
      </div>
      <div class="cols2">
        <div class="form-group my-1">
          <label class="form-label">Estado</label>
          <select
            id="publicado"
            class="form-control"
            v-model="noticia.publicado"
          >
            <option :value="1">Publicado</option>
            <option :value="0">Sin publicar</option>
          </select>
        </div>

        <div class="form-group my-1">
          <label class="form-label">Requiere firma</label>
          <select
            id="requiere_firma"
            class="form-control"
            v-model="noticia.requiere_firma"
          >
            <option :value="0">NO</option>
            <option :value="1">SI</option>
          </select>
        </div>
      </div>
      <template v-if="noticia.requiere_firma">
        <div class="cols2">
          <div class="form-group my-1">
            <label class="form-label">Requiere que todos se notifiquen</label>
            <select class="form-control" id="all-users" v-model="firman_todos">
              <option :value="true">SI</option>
              <option :value="false">NO</option>
            </select>
          </div>
        </div>
        <div v-if="noticia.requiere_firma && !firman_todos" class="mb-2">
          <table-list
            :showFilter="true"
            :data="listadoDePersonas"
            :header="firmasTableList.headers"
            :body="firmasTableList.body"
            :actionButton="firmasTableList.actionButton"
            @rowClicked="deleteEmpleadoFromList"
          ></table-list>
        </div>
      </template>
      <button class="btn btn-primary" @click="save">Guardar</button>
    </div>
    <div class="noticias">
      <div class="card">
        <h4>Previsualización de cartelera</h4>
        <hr />
        <div class="noticias">
          <div class="card" :class="classNoticia">
            <h4 class="card-title">{{ noticia.titulo }}</h4>
          </div>
        </div>
        <h4 class="mt-2">Previsualización de noticia completa</h4>
        <hr />
        <div>
          <h2 class="card-title text-center mt-2 mb-2">{{ noticia.titulo }}</h2>
          <div class="d-flex" v-if="noticia.archivo_url">
            <embed
              :src="noticia.archivo_url"
              style="width: 100%; height: 29.7cm"
              frameborder="0"
            />
          </div>
          <div style="padding: 0 5px" v-html="noticia.contenido" v-else></div>
        </div>
      </div>
    </div>

    <modal v-if="openAddEmpleado" @close="closeAddEmpleado">
      <AddEmpleadoParaNotificar
        slot="modal-body"
        :personas="listaDePersonasParaSeleccionar"
        :tablelist="personasTableList"
        @addEmpleado="addEmpleadoToList"
      />
    </modal>
  </div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';
import Swal from 'sweetalert2';
import { NoticiasServices } from '../../noticias/services/NoticiasServices';
import TableList from '@/components/elements/TableList';
import { PersonasService } from '../../personas/services/PersonasServices';
import Modal from '@/components/elements/Modal';
import AddEmpleadoParaNotificar from './AddEmpleadoParaNotificar.vue';
export default {
  name: 'EditarNoticia',
  components: {
    quillEditor,
    TableList,
    Modal,
    AddEmpleadoParaNotificar,
  },
  data() {
    return {
      noticia: null,
      firman_todos: false,
      editorOption: {
        theme: 'snow',
      },
      openAddEmpleado: false,
      firmasTableList: {
        headers: [
          { value: 'Legajo', sortable: true },
          { value: 'Persona', sortable: true },
          { value: 'Gerencia', sortable: true },
          { value: 'Area', sortable: true },
          { value: 'Notificado', sortable: true },
        ],
        body: [
          {
            class: 'w-100',
            data: [
              {
                value: 'user.persona.numero_legajo',
                class: '',
                label: 'Legajo',
              },
            ],
          },
          {
            class: 'important',
            data: [
              { value: 'user.persona.nombre', class: 'text-primary' },
              {
                value: 'user.persona.cuil',
                class: 'text-small text-secondary',
                mutator: (cuil) => {
                  return PersonasService.formatCuil(cuil);
                },
              },
            ],
          },
          {
            class: 'w-100',
            data: [
              { value: 'user.persona.gerencia.descripcion', label: 'Gerencia' },
            ],
          },
          {
            class: 'w-100',
            data: [{ value: 'user.persona.area.descripcion', label: 'Area' }],
          },
          {
            class: 'important',
            data: [
              {
                value: 'firmado',
                mutator: (item) => {
                  return item ? 'SI' : 'NO';
                },
              },
            ],
          },
        ],
        actionButton: {
          text: 'Añadir',
          action: () => {
            this.openAddEmpleado = true;
          },
        },
      },
      personasTableList: {
        headers: [
          { value: 'Legajo', sortable: true },
          { value: 'Persona', sortable: true },
          { value: 'Gerencia', sortable: true },
          { value: 'Area', sortable: true },
        ],
        body: [
          {
            class: 'w-100',
            data: [{ value: 'numero_legajo', class: '', label: 'Legajo' }],
          },
          {
            class: 'important',
            data: [
              { value: 'nombre', class: 'text-primary' },
              {
                value: 'cuil',
                class: 'text-small text-secondary',
                mutator: (cuil) => {
                  return PersonasService.formatCuil(cuil);
                },
              },
            ],
          },
          {
            class: 'w-100',
            data: [{ value: 'gerencia.descripcion', label: 'Gerencia' }],
          },
          {
            class: 'w-100',
            data: [{ value: 'area.descripcion', label: 'Area' }],
          },
        ],
      },
      personas: [],
      usuarios_notificables: [],
    };
  },
  computed: {
    classNoticia() {
      let clase = '';
      if (this.noticia.tipo) {
        clase = `card-${this.noticia.tipo}`;
      }
      return clase;
    },
    listadoDePersonas() {
      return this.usuarios_notificables.filter((firma) => {
        if (firma.deleted === true) {
          return false;
        }
        return true;
      });
    },
    listaDePersonasParaSeleccionar() {
      return this.personas.filter((persona) => {
        let exists = true;
        this.usuarios_notificables.forEach((firma) => {
          if (firma.user.persona.id === persona.id && firma.deleted === false) {
            exists = false;
          }
        });
        return exists;
      });
    },
  },
  created() {
    const noticiaId = this.$route.params.id;
    PersonasService.api.getAll().then((personas) => (this.personas = personas));
    NoticiasServices.api
      .find(noticiaId)
      .then((response) => {
        this.noticia = response.noticia;
        this.usuarios_notificables = this.noticia.usuarios_notificables;
        this.usuarios_notificables.forEach((firma) => {
          firma.deleted = false;
        });
        this.usuarios_notificables.sort((a, b) => a.user.id - b.user.id);
      })
      .catch((error) => {
        Swal.fire('Error', `${error}`, 'error');
      });
  },
  methods: {
    save() {
      const formData = new FormData();
      formData.append('titulo', this.noticia.titulo);
      formData.append('contenido', this.noticia.contenido);
      const archivo = document.querySelector('input#archivo');
      formData.append('archivo', archivo.files[0]);
      formData.append('tipo', this.noticia.tipo);
      formData.append('desde', this.noticia.desde);
      formData.append('hasta', this.noticia.hasta);
      formData.append('publicado', this.noticia.publicado);
      formData.append('requiere_firma', this.noticia.requiere_firma);

      if (this.noticia.requiere_firma && this.firman_todos) {
        console.log('si')

        const firmas = this.usuarios_notificables.map((firma) => {
          firma.deleted = false;
          return firma;
        });

        this.personas.forEach((persona) => {
          let exists = firmas.find((firma) => firma.user.persona.id === persona.id)
          if(!exists){
            firmas.push(this.createNewFirmaFromPersona(persona))
          }
        });
        console.log(firmas)
        formData.append(
          'empleados_notificables',
          JSON.stringify(firmas)
        );
      } else if (this.noticia.requiere_firma && !this.firman_todos) {
        formData.append(
          'empleados_notificables',
          JSON.stringify(this.usuarios_notificables),
        );
      } else {
        console.log('else')
        formData.append('empleados_notificables', '[]');
      }
      NoticiasServices.api
        .put(this.noticia.id, formData)
        .then((response) => {
          if (response.success) {
            Swal.fire('Éxito', 'Se guardó correcamente', 'success');
            this.$router.push({ name: 'ListarProcedimientosAdmin' });
          } else {
            Swal.fire('Error', 'No se pudo guardar', 'error');
          }
        })
        .catch((error) => {
          Swal.fire(
            'Error',
            `<p>Error de servidor</p><p>${error}</p>`,
            'error',
          );
        });
    },
    deleteEmpleadoFromList(firma) {
      if (!firma.firmado) {
        Swal.fire({
          title: 'Confirme',
          html: `<p>¿Está seguro que quitar a <b>${firma.user.persona.nombre}</b> de la lista?</p>`,
          icon: 'question',
          showConfirmButton: true,
          confirmButtonText: 'Eliminar',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            const index = this.usuarios_notificables.findIndex(
              (item) => item === firma,
            );

            firma.deleted = true;

            this.$set(this.usuarios_notificables, index, firma);
          }
        });
      }
    },
    addEmpleadoToList(empleado) {
      const alreadyLoadedEmpleado = this.usuarios_notificables.find(
        (firma) => firma.user.persona.id === empleado.id,
      );
      if (alreadyLoadedEmpleado) {
        alreadyLoadedEmpleado.deleted = false;
      } else {
        this.usuarios_notificables.push(
          this.createNewFirmaFromPersona(empleado),
        );
      }
      this.usuarios_notificables.sort((a, b) => a.user.id - b.user.id);
    },
    closeAddEmpleado() {
      this.openAddEmpleado = false;
    },
    createNewFirmaFromPersona(empleado) {
      const newFirma = {
        firmado: 0,
        id: null,
        noticia_id: this.noticia.id,
        user: empleado.user,
        user_id: empleado.user.id,
        added: true,
        deleted: false,
      };
      newFirma.user.persona = {
        id: empleado.id,
        numero_legajo: empleado.numero_legajo,
        nombre: empleado.nombre,
        cuil: empleado.cuil,
        gerencia: empleado.gerencia,
        area: empleado.area,
      };
      return newFirma;
    },
  },
};
</script>
